import { LocalizationProvider, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { SecuredLayout } from '../Layout/SecuredLayout';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import huLocale from 'date-fns/locale/hu';
import { Grid, Stack, Typography, CircularProgress, Button, Breadcrumbs, Link as MuiLink } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { DataGridPro, GridColDef, getGridStringOperators, huHU } from '@mui/x-data-grid-pro';
import { PlanResponse, Plans } from '../../@types/requirements';
import { StyledDetailsCard, StyledDateRangeCard, StyledMainPaper } from '../Layout/styles';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import SpeedIcon from '@mui/icons-material/Speed';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateToYyyyMMdd, getDaysArray } from '../../utils/date-format-handler';

export default function PlanList() {
    const { user } = useAuth();
    const { showError, showResponseError } = useProvideSnackBar();
    const [plans, setPlans] = useState<PlanResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const [from, setFrom] = useState<Date>(new Date(new Date().getFullYear() - 1, 0, 1));
    const [to, setTo] = useState<Date>(new Date());

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Terv azonosító',
            width: 125,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Terv azonosító</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
            renderCell: ({ row }) => {
                return (
                    <MuiLink
                        component={Link}
                        underline='hover'
                        sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                        color='inherit'
                        to={`/planner?planID=${row.id}`}>
                        {row.id}
                    </MuiLink>
                );
            },
        },
        {
            field: 'workplace',
            headerName: 'Munkahely',
            width: 150,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Munkahely</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'dates',
            headerName: 'Érvényességi időszak',
            width: 250,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Érvényességi időszak</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'direction',
            headerName: 'Szállítás iránya',
            width: 150,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Szállítás iránya</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
            renderCell: ({ value }) => {
                if (value === 'haza') {
                    return (
                        <Stack direction='row' spacing={2} alignItems='center'>
                            <HomeIcon fontSize='small' sx={{ mr: 1 }} />
                            {value}
                        </Stack>
                    );
                }
                return (
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <BusinessIcon fontSize='small' sx={{ mr: 1 }} />
                        {value}
                    </Stack>
                );
            },
        },
        {
            field: 'shiftName',
            headerName: 'Műszakok',
            width: 150,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Műszakok</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'operationUnitName',
            headerName: 'Üzemegység',
            width: 280,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Üzemegység</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'mobileSyncedAt',
            headerName: 'Szinkronizálás dátuma',
            width: 200,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Szinkronizálás</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'audit',
            headerName: 'Audit',
            width: 350,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Audit</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
            renderCell: ({ value }) => {
                return (
                    <Stack direction='column'>
                        <Typography variant='body2'>{value.created}</Typography>
                        <Typography variant='body2'>{value.updated}</Typography>
                    </Stack>
                );
            },
        },
        {
            field: 'button',
            headerName: 'Részletek',
            width: 130,
            sortable: true,
            filterable: false,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Részletek</strong>,
            renderCell: ({ row }) => {
                return (
                    <Button
                        variant='text'
                        sx={{ borderRadius: '50px' }}
                        onClick={() => navigate(`/planner?planID=${row.id}`)}
                        endIcon={<ArrowForwardIosIcon sx={{ fontSize: '13px' }} />}
                        size={'small'}>
                        Részletek
                    </Button>
                );
            },
        },
    ];

    const handleDateRangeChange = (newValue: [Date | null, Date | null]) => {
        if (newValue[0] && newValue[1]) {
            setFrom(newValue[0]);
            setTo(newValue[1]);
        }
    };

    const fetchPlans = async () => {
        setLoading(true);
        try {
            const fromDateStr = formatDateToYyyyMMdd(from);
            const toDateStr = formatDateToYyyyMMdd(to);

            const response = await fetch(`/api/plans/list?startAt=${fromDateStr}&endAt=${toDateStr}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                const existingPlans: Plans = await response.json();
                setPlans(existingPlans.Plans);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError('Hiba a szerver elérésében! ' + error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPlans();
    }, [from, to]);

    return (
        <SecuredLayout>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={huLocale}>
                <Grid container py={1} item xs={12} textAlign={'right'} sx={{ justifyContent: 'space-between' }}>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/planner'>
                            Tervező
                            <EditRoadIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                    </Breadcrumbs>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/speed-limits'>
                            Sebességlimitek
                            <SpeedIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/schedule'>
                            Menetrend
                            <VisibilityIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                    </Breadcrumbs>
                </Grid>
                <Grid container>
                    <StyledMainPaper>
                        <StyledDateRangeCard>
                            <DateRangePicker
                                label={'Kérem válasszon egy időszakot'}
                                sx={{ width: '14rem' }}
                                value={[from, to]}
                                onChange={handleDateRangeChange}
                                slots={{ field: SingleInputDateRangeField }}
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        fullWidth: true,
                                    },
                                }}
                                calendars={2}
                            />
                        </StyledDateRangeCard>
                        <StyledDetailsCard>
                            {plans.length > 0 ? (
                                <Stack
                                    sx={{
                                        height: 'calc(100vh - 200px)',
                                    }}>
                                    <DataGridPro
                                        density={'compact'}
                                        columns={columns}
                                        rows={plans.map(plan => ({
                                            id: plan.ID,
                                            workplace: plan.Workplace,
                                            dates: getDaysArray(new Date(plan.StartAt), new Date(plan.EndAt))
                                                .map(value => value.toLocaleDateString('hu-HU', { month: '2-digit', day: '2-digit' }))
                                                .join(', '),
                                            direction: plan.Direction === 'TO_HOME' ? 'haza' : 'munkahely',
                                            shiftName: plan.ShiftName,
                                            mobileSyncedAt: plan.MobileAppSyncedAt
                                                ? new Date(plan.MobileAppSyncedAt).toLocaleString('hu-HU')
                                                : 'Nincs szinkronizálva',
                                            audit: {
                                                created: 'Létrehozva: ' + new Date(plan.CreatedAt).toLocaleString('hu-HU') + ' - ' + plan.CreatedBy,
                                                updated: plan.LastChangedAt
                                                    ? 'Módosítva: ' + new Date(plan.LastChangedAt).toLocaleString('hu-HU') + ' - ' + plan.UpdatedBy
                                                    : 'Nem történt módosítás',
                                            },
                                            operationUnitName: plan.OperationUnitName ? plan.OperationUnitName : 'Összes üzemegység',
                                        }))}
                                        rowHeight={50}
                                        pagination
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 100 } },
                                        }}
                                        pageSizeOptions={[100, 200, 300]}
                                        localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
                                        unstable_headerFilters
                                    />
                                </Stack>
                            ) : (
                                <Grid
                                    container
                                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '600px' }}>
                                    <Grid item>
                                        <Typography variant='h6'>
                                            {loading ? (
                                                <Stack direction='row' spacing={2} alignItems='center'>
                                                    <CircularProgress
                                                        sx={{
                                                            fontSize: '60px',
                                                        }}
                                                    />
                                                    <Typography variant='h6'>Adatok betöltése...</Typography>
                                                </Stack>
                                            ) : (
                                                'Nincs megjeleníthető adat'
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </StyledDetailsCard>
                    </StyledMainPaper>
                </Grid>
            </LocalizationProvider>
        </SecuredLayout>
    );
}
