import { Checkbox, Grid } from '@mui/material';
import { Passenger } from '../../@types/requirements';
import { useAuth, useProvideSnackBar } from '../../hooks';

interface PassengerCheckboxesCellProps {
    passenger: Passenger;
    fetchRequirements: () => void;
}

const PassengerCheckboxesCell: React.FC<PassengerCheckboxesCellProps> = ({ passenger, fetchRequirements }) => {
    const { showError, showResponseError } = useProvideSnackBar();
    const { user } = useAuth();

    const fetchPassengerTravel = async () => {
        try {
            const response = await fetch('/api/travels', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    RequirementStopPassengerID: passenger.ID,
                    Travel: !passenger.Travels,
                }),
            });

            if (response.ok) {
                fetchRequirements();
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError('Hiba történt: ' + error.message);
        }
    };

    return (
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Checkbox
                size='small'
                key={passenger.ID}
                checked={passenger.Travels}
                onChange={event => {
                    fetchPassengerTravel();
                }}
                sx={{ padding: 0, paddingTop: 0 }}
            />
        </Grid>
    );
};

export default PassengerCheckboxesCell;
