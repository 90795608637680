import { TimePicker } from '@mui/x-date-pickers-pro';
import { useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { Line } from '../../@types/lines';
import { Box } from '@mui/material';

interface LineShiftUpdateTimePickerParams {
    lines: Line[];
    field: 'ShiftArrivalAt' | 'ShiftDepartureAt';
}

export default function LineShiftUpdateTimePicker({ lines, field }: LineShiftUpdateTimePickerParams) {
    const { user } = useAuth();
    const { showResponseError } = useProvideSnackBar();

    let initialHour = 0;
    let initialMinute = 0;

    let [tmpHour, tmpMinute] = lines[0][field].split(':');
    if (parseInt(tmpHour) > 0) {
        initialHour = parseInt(tmpHour);
    }
    if (parseInt(tmpMinute) > 0) {
        initialMinute = parseInt(tmpMinute);
    }

    const initialTime = new Date();
    initialTime.setHours(initialHour, initialMinute, 0, 0);
    const [value, setValue] = useState<Date>(initialTime);

    const save = async (hour: number, minute: number) => {
        const ids = lines.map((line: Line) => line.ID);

        const response = await fetch('/api/lines', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                Lines: ids.map((id: number) => {
                    return {
                        ID: id,
                        [field]: `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`,
                    };
                }),
            }),
        });

        if (!response.ok) {
            await showResponseError(response);
        }
    };

    return (
        <Box
            onKeyDown={e => {
                e.stopPropagation();
            }}>
            <TimePicker
                ampm={false}
                openTo='hours'
                views={['hours', 'minutes']}
                value={value}
                onChange={newValue => {
                    if (newValue) {
                        setValue(newValue);

                        if (!Number.isNaN(newValue.getHours())) {
                            save(newValue.getHours(), newValue.getMinutes());
                        }
                    }
                }}
                slotProps={{
                    textField: {
                        variant: 'standard',
                        fullWidth: true,
                        sx: {
                            '.MuiButtonBase-root': {
                                padding: '0',
                            },
                        },
                    },
                }}
            />
        </Box>
    );
}
