import React, { memo, useEffect, useState } from 'react';
import { FormControl, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DirectionType, Requirement } from '../../@types/requirements';
import { DailyPassengers, Line, LineStop, LinesResposne, SortableLineStop } from '../../@types/lines';
import { useAuth, useProvideSnackBar } from '../../utils';
import LineShiftUpdateTimePicker from './LineShiftUpdateTimePicker';

import { DataGridPro, DataGridProProps, GRID_TREE_DATA_GROUPING_FIELD, GridColDef, GridPinnedRowsProp, GridRowsProp } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import LineStopTimePicker from './LineStopTimePicker';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import StopDndContext from './StopDndContext';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import CustomGridTreeDataGroupingCell from './CustomGridTreeDataGroupingCell';
import PassengerCheckboxesCell from './PassengerCheckboxesCell';
import { useExpandedRows } from '../../hooks/useExpandedRowsContext';
import { Passenger } from '../../@types/requirements';
import ErrorBoundary from '../../hooks/errorBoundary';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import FactoryIcon from '@mui/icons-material/Factory';

type LineStopListProps = {
    requirements: Requirement[];
    lines: Line[];
    lineStops: SortableLineStop[];
    afterChangeCallback: () => void;
    dailyPassengers: DailyPassengers;
    lineNames: string[];
    allLines: Line[];
    fetchRequirements: () => void;
    setLineStops: (stops: SortableLineStop[]) => void;
    direction: keyof typeof DirectionType;
    sortStops: (lines: Line[]) => SortableLineStop[];
};

const LineStopList = memo(
    ({
        requirements,
        lines,
        lineStops,
        afterChangeCallback,
        lineNames,
        allLines,
        fetchRequirements,
        setLineStops,
        direction,
        sortStops,
    }: LineStopListProps) => {
        const { showError, showResponseError } = useProvideSnackBar();
        const { user } = useAuth();
        const [rows, setRows] = useState<GridRowsProp>([]);
        const [activeStop, setActiveStop] = useState<SortableLineStop | null>(null);
        const { expandedRows } = useExpandedRows();
        const [gridPinRows, setGridPinRows] = useState<GridPinnedRowsProp>();

        const handleDeleteLineStop = async (stopName: String) => {
            const response = await fetch('/api/linestop', {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    ID: lines
                        .flatMap(l => l.Stops)
                        .filter((lineStop: LineStop) => stopName === lineStop.Name)
                        .map((stop: LineStop) => stop.ID),
                }),
            });
            if (!response.ok) {
                showError(`${response.status}`);
                return;
            }
            afterChangeCallback();
        };

        const handleDeleteLineStopPassenger = async (workerID: number) => {
            const response = await fetch('/api/linestop/passenger', {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    LineIDs: lines.map(line => line.ID),
                    LineStopPassengerIDs: lines
                        .flatMap(line => line.Stops)
                        .flatMap(stop => stop.Passengers)
                        .filter(passenger => passenger.WorkerID === workerID)
                        .map(passenger => passenger.ID),
                }),
            });
            if (!response.ok) {
                showError(`${response.status}`);
                return;
            }
            afterChangeCallback();
        };

        const saveStopChange = async (updatedStops: LineStop[]) => {
            const response = await fetch('/api/linestop', {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    Stops: updatedStops.map(stop => {
                        return {
                            ID: stop.ID,
                            Position: stop.Position,
                        };
                    }),
                }),
            });
            if (!response.ok) {
                showResponseError(response);
            }
            const body: LinesResposne = await response.json();
            const respLines: Line[] = body.Lines.filter((line: Line) => line.Name === lines[0].Name);
            setLineStops(sortStops(respLines));
        };

        const updateExchangeFetch = async (lineStopID: LineStop[], targetLineIDs: number[]) => {
            try {
                const response = await fetch('/api/lines/exchange', {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user?.accessToken}`,
                    },
                    body: JSON.stringify({
                        targetLineIDs: targetLineIDs,
                        lineStopIDs: lineStopID.map(stop => stop.ID),
                    }),
                });
                if (response.ok) {
                    afterChangeCallback();
                } else {
                    throw new Error('Failed to update data');
                }
            } catch (error: any) {
                showError(`Hiba történt: ${error.message}`);
            }
        };

        const handleLineStopExchange = async (lineName: string, lineStop: LineStop[]) => {
            const filteredLines = allLines.filter(line => line.Name === lineName).map(line => line.ID);

            try {
                updateExchangeFetch(lineStop, filteredLines);
            } catch (error: any) {
                showError(`Hiba történt a megálló áthelyezése közben: ${error.message}`);
            }
        };

        const getPinRows = (): GridPinnedRowsProp => {
            const pinnedRows: GridPinnedRowsProp = {
                top: [],
                bottom: [],
            };

            const initializePinnedRow = (id: number, name: string) => ({
                id: id,
                name: name,
                ...Object.fromEntries(requirements.map(r => ['requirement' + r.DueAt, 0])),
            });

            const distanceTraveledRow: { [key: string]: any } = initializePinnedRow(-4, 'Megtett távolság');
            const roadFeeM2Row: { [key: string]: any } = initializePinnedRow(-5, 'Útdíj (M2 J2, E5)');
            const roadFeeM3J2Row: { [key: string]: any } = initializePinnedRow(-6, 'Útdíj (M3 J2, E5)');
            const roadFeeM3J3Row: { [key: string]: any } = initializePinnedRow(-7, 'Útdíj (M3 J3, E5)');
            requirements.forEach(r => {
                const line = lines.find(l => l.RequirementID === r.ID);
                if (line) {
                    distanceTraveledRow['requirement' + r.DueAt] = Math.round(line.TravelDistance || 0);
                    roadFeeM2Row['requirement' + r.DueAt] = Math.round(line.RoadFeeM2 || 0);
                    roadFeeM3J2Row['requirement' + r.DueAt] = Math.round(line.RoadFeeM3J2 || 0);
                    roadFeeM3J3Row['requirement' + r.DueAt] = Math.round(line.RoadFeeM3J3 || 0);
                }
            });

            if (direction === 'TO_HOME') {
                pinnedRows.bottom = [distanceTraveledRow, roadFeeM2Row, roadFeeM3J2Row, roadFeeM3J3Row];
            } else if (direction === 'FROM_HOME') {
                const returnFactoryRow: { [key: string]: any } = initializePinnedRow(-3, 'Visszaindulás a gyártól');

                requirements.forEach(r => {
                    const travelingPassengerWorkerIDs = r.Stops.flatMap(requirementStop => requirementStop.Passengers)
                        .filter(passenger => passenger.Travels)
                        .map(passenger => passenger.WorkerId);

                    const line = lines.find(l => l.RequirementID === r.ID);
                    if (line) {
                        returnFactoryRow['requirement' + r.DueAt] = lines
                            .flatMap(line => line.Stops)
                            .flatMap(ls => ls.Passengers)
                            .filter(passenger => passenger.RequirementID === r.ID && travelingPassengerWorkerIDs.includes(passenger.WorkerID)).length;
                    }
                });

                pinnedRows.bottom = [returnFactoryRow, distanceTraveledRow, roadFeeM2Row, roadFeeM3J2Row, roadFeeM3J3Row];
            }

            return pinnedRows;
        };

        const createRowsFromStops = (stops: SortableLineStop[], requirements: Requirement[]): GridRowsProp => {
            let rows: GridRowsProp = [];

            const crmIDs = lines
                .flatMap(line => line.Stops)
                .filter(stop => stop.Type === 'stop')
                .map(stop => stop.CrmID);

            stops.forEach(stop => {
                const stopData: { [key: string]: any } = {
                    id: stop.ID,
                    crmId: stop.CrmID,
                    path: stop.Name,
                    name: stop.Name,
                };

                if (stop.Type === 'stop') {
                    requirements.forEach(r => {
                        const requirementStop = r.Stops.find(s => s.CrmID === stop.CrmID && s.Name === stop.Name);

                        const passengers = lines
                            .flatMap(line => line.Stops)
                            .flatMap(ls => ls.Passengers)
                            .filter(p => p.StopCrmID === stop.CrmID && p.RequirementID === r.ID)
                            .filter(p => {
                                const rsPassenger = requirementStop?.Passengers.find(rs => rs.WorkerId === p.WorkerID);
                                if(!rsPassenger) {
                                    return false;
                                }

                                if(!rsPassenger.Travels) {
                                    return false;
                                }

                                return true;
                            });

                        stopData['requirement' + r.DueAt] = passengers.length;

                    
                        if (requirementStop) {
                            passengers.forEach(p => {
                                const passenger = rows.find(row => row.id === p.WorkerID);
                                if (!passenger) {
                                    const passengerData: { [key: string]: any } = {
                                        id: p.WorkerID,

                                        path: stop.Name + '//' + p.Name + ' ' + p.WorkerID,
                                        type: 'PASSENGER',
                                        name: p.Name,
                                    };
                                    requirements.forEach(r => (passengerData['requirement' + r.DueAt] = 0));

                                    passengerData['requirement' + r.DueAt] = 1;
                                    rows = rows.concat(passengerData);
                                } else {
                                    passenger['requirement' + r.DueAt] = 1;
                                }
                            });
                        }
                    });
                }

                if (stop.Type === 'site') {
                    stopData['nSitePassengers'] = 0;

                    for (const r of requirements) {
                        stopData['requirement' + r.DueAt] = 0;

                        for (const iterStop of r.Stops) {
                            if (!crmIDs.includes(iterStop.CrmID)) {
                                continue;
                            }

                            for (const passenger of iterStop.Passengers) {
                                if (
                                    passenger.Travels &&
                                    passenger.SiteName === stop.Name &&
                                    iterStop.RequirementID === r.ID &&
                                    lines
                                        .flatMap(line => line.Stops)
                                        .flatMap(ls => ls.Passengers)
                                        .some(p => p.WorkerID === passenger.WorkerId)
                                ) {
                                    stopData['requirement' + r.DueAt] += 1;
                                    stopData['nSitePassengers'] += 1;
                                }
                            }
                        }
                    }
                }

                rows = rows.concat(stopData);
            });
            return rows;
        };

        useEffect(() => {
            const rows = createRowsFromStops(lineStops, requirements);
            setRows([...rows]);
            const pinRows = getPinRows();
            setGridPinRows(pinRows);
        }, [requirements, lineStops]);

        const getRequirementPassenger = (r: Requirement, id: number) => {
            let passenger: Passenger | undefined;
            r.Stops.forEach(s => {
                s.Passengers.forEach(p => {
                    if (p.WorkerId === id) {
                        passenger = p;
                    }
                });
            });
            return passenger;
        };

        const isStopNotAllPassengerHeadquarter = (id: number) => {
            let stop = requirements.flatMap(r => r.Stops).find(s => s.CrmID === id);
            if (!stop) {
                requirements.forEach(r => {
                    r.Stops.forEach(s => {
                        s.Passengers.forEach(p => {
                            if (p.WorkerId === id) {
                                stop = s;
                            }
                        });
                    });
                });
                if (!stop) {
                    return false;
                }
            }
            return !stop?.AllPassengerHeadquarter;
        };

        const requirementColumns: GridColDef[] = requirements.map(r => {
            return {
                field: 'requirement' + r.DueAt,
                headerName: `${new Date(Date.parse(r.DueAt)).toLocaleDateString('hu-HU', { weekday: 'narrow' })}`,
                width: 35,
                minWidth: 35,
                maxWidth: 35,
                sortable: false,
                disableColumnMenu: true,
                headerAlign: 'center',
                align: 'center',
                headerClassName: 'requirement-header',
                resizable: false,
                renderCell: params => {
                    if (params.row.type === 'PASSENGER') {
                        const passenger = getRequirementPassenger(r, params.row.id);
                        if (!passenger) {
                            return <span style={{ margin: '0 2px' }}>-</span>;
                        }
                        return <PassengerCheckboxesCell passenger={passenger} fetchRequirements={fetchRequirements} />;
                    }
                },
            };
        });

        const columns: GridColDef[] = [
            {
                field: 'OurReorderCell',
                headerName: ' ',
                flex: 1,
                width: 20,
                minWidth: 20,
                maxWidth: 20,
                disableColumnMenu: true,
                sortable: false,
                headerAlign: 'left',
                align: 'left',
                resizable: false,
                renderCell: params => {
                    const stop = lineStops.find(stop => stop.CrmID === params.row.crmId && stop.Name === params.row.name);
                    if (!stop) {
                        return '';
                    }
                    return <StopDndContext stop={stop} />;
                },
            },
            {
                field: GRID_TREE_DATA_GROUPING_FIELD,
            },
            {
                field: 'name',
                headerName: ' ',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
                headerAlign: 'left',
                align: 'left',
                resizable: false,
                renderCell: params => {
                    if (params.row.id < 0) {
                        return params.row.name;
                    }
                    return (
                        <Typography
                            m={0}
                            p={0}
                            paragraph
                            sx={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                                maxWidth: '90%',
                                backgroundColor: isStopNotAllPassengerHeadquarter(params.row.id) ? 'yellow' : '',
                            }}
                            title={params.row.name}>
                            {params.row.name}
                        </Typography>
                    );
                },
            },
            {
                field: 'lineStopExchange',
                headerName: ' ',
                width: 55,
                minWidth: 55,
                maxWidth: 55,
                sortable: false,
                disableColumnMenu: true,
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                renderCell: params => {
                    const stop = lineStops.find(stop => stop.CrmID === params.row.crmId && stop.Name === params.row.name);
                    if (!stop) {
                        return '';
                    }
                    if (stop.Type === 'site') {
                        return <FactoryIcon />;
                    }
                    return (
                        <FormControl size={'small'}>
                            <Select
                                variant='standard'
                                value=''
                                sx={{ marginRight: '4px', '& .MuiSelect-select': { padding: 0 } }}
                                defaultValue=''
                                onChange={e =>
                                    handleLineStopExchange(
                                        e.target.value,
                                        allLines.flatMap(l => l.Stops).filter((lineStop: LineStop) => params.row.name === lineStop.Name)
                                    )
                                }
                                displayEmpty
                                renderValue={value => <ImportExportIcon fontSize={'small'} sx={{ px: 0.5, pt: 0.5, pb: 0 }} />}>
                                {lineNames
                                    .filter(lineName => lineName !== lines[0].Name)
                                    .map(lineName => (
                                        <MenuItem value={lineName} key={lineName}>
                                            {lineName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    );
                },
            },
            {
                field: 'lineStopPicker',
                headerName: 'Indulás',
                width: 50,
                minWidth: 50,
                maxWidth: 50,
                sortable: false,
                disableColumnMenu: true,
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                renderCell: params => {
                    switch (params.row.id) {
                        case -1:
                            return <LineShiftUpdateTimePicker lines={lines} field='ShiftDepartureAt' />;
                        case -3:
                            return <LineShiftUpdateTimePicker lines={lines} field='ShiftDepartureAt' />;
                        case -4:
                            return '';
                        default: {
                            const stop = lineStops.find(stop => stop.CrmID === params.row.crmId && stop.Name === params.row.name);
                            if (stop) {
                                return <LineStopTimePicker lines={lines} lineStop={stop} setLineStops={setLineStops} sortStops={sortStops} />;
                            }
                            return '';
                        }
                    }
                },
            },
            {
                field: 'priorityHighIcon',
                headerName: ' ',
                width: 10,
                minWidth: 10,
                maxWidth: 10,
                sortable: false,
                disableColumnMenu: true,
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                renderCell: params => {
                    const currentStop = lineStops.find(stop => stop.CrmID === params.row.crmId && stop.Name === params.row.name);
                    if (!currentStop) {
                        return '';
                    }

                    const prevStop = lineStops.find(stop => stop.Position === currentStop.Position - 1);
                    const nextStop = lineStops.find(stop => stop.Position === currentStop.Position + 1);

                    const isBeforeMidnightTransition = currentStop.DepartureHour === 23 && nextStop && nextStop.DepartureHour === 0;
                    const isAfterMidnightTransition = currentStop.DepartureHour === 0 && prevStop && prevStop.DepartureHour === 23;

                    if (isBeforeMidnightTransition || isAfterMidnightTransition) {
                        return '';
                    }

                    const isCurrentAfterPrev =
                        prevStop &&
                        (currentStop.DepartureHour > prevStop.DepartureHour ||
                            (currentStop.DepartureHour === prevStop.DepartureHour && currentStop.DepartureMinute > prevStop.DepartureMinute));

                    const isCurrentBeforeNext =
                        nextStop &&
                        (currentStop.DepartureHour < nextStop.DepartureHour ||
                            (currentStop.DepartureHour === nextStop.DepartureHour && currentStop.DepartureMinute < nextStop.DepartureMinute));

                    if (prevStop && !isCurrentAfterPrev) {
                        return <PriorityHighIcon sx={{ color: 'red' }} />;
                    }

                    if (nextStop && !isCurrentBeforeNext) {
                        return <PriorityHighIcon sx={{ color: 'red' }} />;
                    }
                    return '';
                },
            },
            ...requirementColumns,
            {
                field: 'delete',
                headerName: ' ',
                width: 40,
                minWidth: 40,
                maxWidth: 40,
                sortable: false,
                disableColumnMenu: true,
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                renderCell: params => {
                    const stop = lineStops.find(stop => stop.CrmID === params.row.crmId && stop.Name === params.row.name);
                    if (stop) {
                        if (stop.Type === 'site') {
                            return (
                                <IconButton onClick={() => handleDeleteLineStop(params.row.name)} sx={{ pointerEvents: 'auto', padding: '5px' }}>
                                    <DeleteIcon sx={{ fontSize: 20 }} />
                                </IconButton>
                            );
                        }

                        if (stop.Type === 'stop') {
                            return (
                                <IconButton onClick={() => handleDeleteLineStop(params.row.name)} sx={{ pointerEvents: 'auto', padding: '5px' }}>
                                    <DeleteIcon
                                        color={
                                            requirements
                                                .map(r => r.Stops.find(s => s.Name === params.row.name)?.NPassengers)
                                                .every(n => n === undefined || n === 0)
                                                ? 'error'
                                                : 'action'
                                        }
                                        sx={{ fontSize: 20 }}
                                    />
                                </IconButton>
                            );
                        }
                    }

                    if (params.row.type === 'PASSENGER') {
                        return (
                            <IconButton onClick={() => handleDeleteLineStopPassenger(params.row.id)} sx={{ pointerEvents: 'auto', padding: '5px' }}>
                                <DeleteIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                        );
                    }
                },
            },
        ];

        const groupingColDef: DataGridProProps['groupingColDef'] = {
            headerName: ' ',
            width: 20,
            minWidth: 20,
            maxWidth: 20,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => <CustomGridTreeDataGroupingCell {...params} />,
        };

        return (
            <Stack>
                <DndContext
                    onDragStart={e => setActiveStop(e.active.data?.current?.stop)}
                    onDragEnd={e => {
                        const { active, over } = e;
                        if (over && active.id !== over?.id) {
                            const originalIdx = lineStops.map(s => s.id).indexOf(active.id);
                            const newIdx = lineStops.map(s => s.id).indexOf(over.id);

                            const reorderedArray =
                                originalIdx > newIdx
                                    ? [
                                          ...lineStops.slice(0, newIdx),
                                          lineStops[originalIdx],
                                          ...lineStops.slice(newIdx, originalIdx),
                                          ...lineStops.slice(originalIdx + 1),
                                      ]
                                    : [
                                          ...lineStops.slice(0, originalIdx),
                                          ...lineStops.slice(originalIdx + 1, newIdx + 1),
                                          lineStops[originalIdx],
                                          ...lineStops.slice(newIdx + 1),
                                      ];

                            const stopNamePositionMap: Record<string, number> = {};

                            reorderedArray.forEach((s, index) => (stopNamePositionMap[s.Name] = index));

                            const allLineStops = lines.flatMap(l =>
                                l.Stops.map(s => {
                                    s.Position = stopNamePositionMap[s.Name];
                                    return s;
                                })
                            );

                            saveStopChange(allLineStops);
                            setLineStops(reorderedArray);
                            return;
                        }
                        setActiveStop(null);
                    }}>
                    <ErrorBoundary>
                        <DataGridPro
                            treeData
                            getTreeDataPath={row => row.path.split('//')}
                            groupingColDef={groupingColDef}
                            columns={columns}
                            rows={rows}
                            pinnedRows={gridPinRows}
                            rowHeight={30}
                            columnHeaderHeight={30}
                            hideFooter={true}
                            isGroupExpandedByDefault={params => {
                                return expandedRows[Number(params.id)] || false;
                            }}
                            sx={{
                                '& .requirement-item-cell-overcapacity': {
                                    color: '#f44336',
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    padding: 0,
                                },
                                '& .MuiDataGrid-cell': {
                                    padding: 0,
                                },
                                '& .requirement-item-cell': {
                                    padding: 0,
                                },
                            }}
                        />
                    </ErrorBoundary>
                    <DragOverlay dropAnimation={null}>{activeStop ? <Typography sx={{ width: 400 }}>{activeStop.Name}</Typography> : null}</DragOverlay>
                </DndContext>
            </Stack>
        );
    }
);

export { LineStopList };
