import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { MapParams } from '../../@types/map';
import L from 'leaflet';
import { Line, LineStop } from '../../@types/lines';
import LineColor from './LineColor';
import { decode } from '@googlemaps/polyline-codec';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { Fab } from '@mui/material';
import { useEffect, useMemo } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { calculateMaxNPassengers } from '../../utils/calculateMaxNPassengers';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const makeColorIcon = (color: string, borderColor: string, passenger: number) => {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 85"><defs><style>.fa-secondary{opacity:.9;}</style></defs><path fill="${color}" stroke="${borderColor}" stroke-width="5"  d="M31.688,2C47.428,2,59,13.989,59,29.729v0.896C59,51.367,41.119,77,31.212,83h-0.237C21.069,77,2,51.367,2,30.625v-0.896C2,13.989,14.76,2,30.5,2C30.659,2,31.529,2,31.688,2z"></path><text  x="50%" y="50%" dy=".13em" font-size="40" font-weight="bold" text-anchor="middle" fill="#FFF">${passenger}</text></svg>`;
    return L.divIcon({
        className: 'custom-div-icon',
        html: `<div style="filter: drop-shadow(1px 1px 2px ${color});">${svg}</div>`,
        iconSize: [25, 10],
        iconAnchor: [15, 40],
        popupAnchor: [-2, -40],
    });
};

const factoryIcon = (color: string) => {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="${color}" d="M335.2 244.7c.5 3.7 .8 7.5 .8 11.3c0 44.2-35.8 80-80 80s-80-35.8-80-80s35.8-80 80-80c3.8 0 7.6 .3 11.3 .8l31.2-31.2L293.7 117c-12-3.3-24.7-5-37.7-5c-79.5 0-144 64.5-144 144s64.5 144 144 144s144-64.5 144-144c0-13.1-1.7-25.7-5-37.7l-28.6-4.8-31.2 31.2zm110.1-20.8c1.8 10.4 2.7 21.1 2.7 32.1c0 106-86 192-192 192S64 362 64 256S150 64 256 64c10.9 0 21.6 .9 32.1 2.7l9.3-9.3 43.1-43.1C314 5 285.6 0 256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256c0-29.6-5-58-14.3-84.5l-43.1 43.1-9.3 9.3zm-80.2-43.1l50.7 8.4c10.2 1.7 20.6-1.6 27.9-8.9l51.2-51.2c8.1-8.1 5.3-21.9-5.4-26.2L432 80 409 22.5c-4.3-10.7-18-13.5-26.2-5.4L331.6 68.4c-7.3 7.3-10.6 17.7-8.9 27.9l8.4 50.7L239 239c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l92.1-92.1z"/></svg>`;
    return L.divIcon({
        className: 'custom-div-icon',
        html: `<div style="background-color: white; border-radius: 50px">${svg}</div>`,
        iconSize: [30, 10],
        iconAnchor: [15, 20],
        popupAnchor: [0, -20],
    });
};

interface StopWithLine {
    ID: number;
    CrmID: number;
    LineID: number;
    LineName: string;
    Name: string;
    Lat: number;
    Lon: number;
    Type: string;
}

type FlyMapToProps = {
    lat: number;
    lon: number;
    mapExpanded: boolean;
};

const FlyMapTo = ({ lat, lon, mapExpanded }: FlyMapToProps) => {
    const map = useMap();

    useEffect(() => {
        map.invalidateSize();
        map.flyTo([lat, lon]);
    }, [lat, lon, mapExpanded]);

    return null;
};

const PlannerMap = ({
    requirements,
    stops,
    onSelectStop,
    lines,
    allLines,
    factory,
    routes,
    mapExpanded,
    setMapExpanded,
    showRoutes,
    setShowRoutes,
    stopCheckbox,
}: MapParams) => {
    const lineStops = lines
        .flatMap((line: Line) => {
            return line.Stops.map(s => {
                return {
                    LineName: line.Name,
                    LineID: line.ID,
                    ...s,
                };
            });
        })
        .filter((value, index, self) => self.map(x => x.Name).indexOf(value.Name) === index);

    const siteStops: LineStop[] = lines
        .flatMap((line: Line) => line.Stops.filter(s => s.Type === 'site'))
        .filter((value, index, self) => self.map(x => x.Name).indexOf(value.Name) === index);

    const stopMaxNPassengers = useMemo(() => {
        return calculateMaxNPassengers(requirements);
    }, [requirements]);

    return (
        <MapContainer center={[factory.lat, factory.lon]} zoom={10} scrollWheelZoom={true} style={{ height: '100%', width: '100%' }}>
            <Fab
                size={'small'}
                sx={{ position: 'absolute', right: '10px', top: '10px' }}
                onClick={() => {
                    setMapExpanded(!mapExpanded);
                }}>
                {mapExpanded ? <ZoomInMapIcon fontSize={'medium'} /> : <ZoomOutMapIcon fontSize={'medium'} />}
            </Fab>
            <FlyMapTo lat={factory.lat} lon={factory.lon} mapExpanded={mapExpanded} />

            <Fab size={'small'} sx={{ position: 'absolute', right: '10px', top: '60px' }} onClick={() => setShowRoutes(!showRoutes)}>
                {showRoutes ? <VisibilityOffIcon fontSize={'medium'} /> : <VisibilityIcon fontSize={'medium'} />}
            </Fab>

            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />

            {siteStops.map(s => (
                <Marker icon={factoryIcon('red')} key={`${s.Lat}_${s.Lon}`} position={[s.Lat, s.Lon]}>
                    <Popup>{factory.name}, {s.Name}</Popup>
                </Marker>
            ))}

            {stops.map(s => (
                <Marker
                    icon={makeColorIcon(
                        'black',
                        stopCheckbox[s.id] ? 'red' : 'black',
                        stopMaxNPassengers[s.crmId] !== undefined ? stopMaxNPassengers[s.crmId] : 0
                    )}
                    key={`${s.lat}_${s.lon}`}
                    position={[s.lat, s.lon]}
                    eventHandlers={{
                        click: () => {
                            const newStopCheckbox = { ...stopCheckbox, [s.id]: !stopCheckbox[s.id] };
                            onSelectStop(newStopCheckbox);
                        },
                    }}>
                    <Popup>{s.name}</Popup>
                </Marker>
            ))}

            {lineStops.map((stop: StopWithLine) => {
                if (stop.Type === 'stop') {
                    return (
                        <Marker
                            icon={makeColorIcon(LineColor(allLines, stop.LineID), LineColor(allLines, stop.LineID), stopMaxNPassengers[stop.CrmID])}
                            key={`marker-${stop.Lat}-${stop.Lon}`}
                            position={[stop.Lat, stop.Lon]}>
                            <Popup>
                                {stop.LineName} <br /> <strong>{stop.Name}</strong>
                            </Popup>
                        </Marker>
                    );
                }
            })}

            {routes.Routes.filter(r => lines.map(ln => ln.ID).indexOf(r.LineID) > -1).map((route, idx) => {
                const points = decode(route.Route, 6);
                return <Polyline key={idx + '_' + route.LineID} positions={points} color={LineColor(allLines, route.LineID)} weight={5} />;
            })}
        </MapContainer>
    );
};

export { PlannerMap };
