import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { GridRenderCellParams, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { ButtonProps, IconButton } from '@mui/material';
import { useExpandedRows } from '../../hooks/useExpandedRowsContext';
import { useEffect } from 'react';

export default function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode, row } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
    const { expandedRows, setExpandedRows } = useExpandedRows();

    useEffect(() => {
        if (rowNode.type === 'group') {
            if (rowNode.childrenExpanded !== expandedRows[row.id]) {
                apiRef.current.setRowChildrenExpansion(id, expandedRows[row.id]);
            }
        }
    }, [rowNode]);

    const handleClick: ButtonProps['onClick'] = event => {
        if (rowNode.type !== 'group') {
            return;
        }

        const isExpanded = !expandedRows[row.id];
        setExpandedRows({ ...expandedRows, [row.id]: isExpanded });
        apiRef.current.setRowChildrenExpansion(id, isExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    return (
        <IconButton
            size='small'
            onClick={handleClick}
            sx={{
                color: 'action',
                visibility: filteredDescendantCount ? 'visible' : 'hidden',
            }}>
            {expandedRows[row.id] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
    );
}
