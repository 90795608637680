import React, { createContext, useContext, useState } from 'react';
import { ExpandedCheckboxContextType, StopIdIsOnMapType } from '../@types/map';

const ExpandedRowsContext = createContext<ExpandedCheckboxContextType>({
  expandedRows: {},
  setExpandedRows: (idMap: StopIdIsOnMapType) => {},
});

export const useExpandedRows = () => useContext(ExpandedRowsContext);

export const ExpandedRowsProvider = ({ children }: { children: React.ReactNode }) => {
  const [expandedRows, setExpandedRows] = useState<StopIdIsOnMapType>({});

  return (
    <ExpandedRowsContext.Provider value={{ expandedRows, setExpandedRows }}>
      {children}
    </ExpandedRowsContext.Provider>
  );
};