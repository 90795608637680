import React, { useEffect, useState } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Passenger, Requirement, Stop } from '../../@types/requirements';
import { LineStop } from '../../@types/lines';
import { DragOverlay } from '@dnd-kit/core';
import { useDraggable } from '@dnd-kit/core';
import { DragIndicator } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    DataGridPro,
    DataGridProProps,
    GRID_TREE_DATA_GROUPING_FIELD,
    GridColDef,
    GridFooterContainer,
    GridRenderCellParams,
    GridRowsProp,
    GridSlotsComponentsProps,
} from '@mui/x-data-grid-pro';
import { RequirementItem } from './RequirementItem';
import CustomGridTreeDataGroupingCell from './CustomGridTreeDataGroupingCell';
import PassengerCheckboxesCell from './PassengerCheckboxesCell';
import { useExpandedRows } from '../../hooks/useExpandedRowsContext';
import ErrorBoundary from '../../hooks/errorBoundary';

type RequirementListProps = {
    requirements: Requirement[];
    stopsOnLines: LineStop[];
    stops: Stop[];
    activeId: string | undefined | null;
    showAllLinesOnMap: boolean;
    setShowAllLinesOnMap: (val: boolean) => void;
    fetchRequirements: () => void;
};

const DataGridFooter = (props: NonNullable<GridSlotsComponentsProps['footer']>) => {
    return (
        <GridFooterContainer>
            {props.summary && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: 1,
                        padding: 1,
                    }}>
                    <Typography variant='h6'>Összegzés</Typography>
                    <Box sx={{ display: 'flex' }}>
                        {Object.keys(props.summary).map((key, index) => (
                            <Typography key={index} variant='body2' px={1.25}>
                                {props.summary ? props.summary[key] : 0}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            )}
        </GridFooterContainer>
    );
};

const RequirementList = ({ requirements, stopsOnLines, stops, activeId, showAllLinesOnMap, setShowAllLinesOnMap, fetchRequirements }: RequirementListProps) => {
    const [rows, setRows] = useState<GridRowsProp>([]);
    const { expandedRows } = useExpandedRows();
    const passenegersOnLines = stopsOnLines.flatMap(stop => stop.Passengers);

    const createRowsFromStops = (stops: Stop[], requirements: Requirement[]): GridRowsProp => {
        let rows: GridRowsProp = [];
        stops.forEach(stop => {
            const stopData: { [key: string]: any } = {
                id: stop.CrmID,
                path: stop.Name,
                name: stop.Name,
            };

            for (const r of requirements) {
                stopData['requirement' + r.DueAt] = 0;

                const requirementStop = r.Stops.find(s => s.CrmID === stop.CrmID);
                if (requirementStop) {
                    stopData['requirement' + r.DueAt] =
                        r.Stops.find(s => s.CrmID === stop.CrmID)?.Passengers.filter(
                            p => !passenegersOnLines.some(passenger => passenger.WorkerID === p.WorkerId)
                        ).length;

                    for (const p of requirementStop.Passengers) {
                        const passenger = rows.find(row => row.id === p.WorkerId);
                        if (!passenger) {
                            if (passenegersOnLines.find(passenger => passenger.WorkerID === p.WorkerId) !== undefined) {
                                continue;
                            }
                            const passengerData: { [key: string]: any } = {
                                id: p.WorkerId,
                                path: stop.Name + '//' + p.Name + ' ' + p.WorkerId,
                                type: 'PASSENGER',
                                name: p.Name,
                            };

                            requirements.forEach(r => (passengerData['requirement' + r.DueAt] = 0));

                            passengerData['requirement' + r.DueAt] = 1;
                            rows = rows.concat(passengerData);
                        } else {
                            passenger['requirement' + r.DueAt] = 1;
                        }
                    }
                }
            }
            rows = rows.concat(stopData);
        });
        return rows;
    };

    const createSummaryRow = (requirements: Requirement[], stopsOnLines: LineStop[]): { [key: string]: any } => {
        const summary: { [key: string]: any } = {};
        const passengersOnLines = stopsOnLines.flatMap(stop => stop.Passengers);

        requirements.forEach(r => {
            const passengers = r.Stops.flatMap(stop => stop.Passengers);

            summary['requirement' + r.DueAt] = passengers.filter(
                passenger => !passengersOnLines.some(passengerOnLine => passengerOnLine.WorkerID === passenger.WorkerId)
            ).length;
        });

        return summary;
    };

    const findStopById = (stops: Stop[], id: number): Stop | undefined => {
        return stops.find(stop => stop.CrmID === id);
    };

    const getRequirementPassenger = (r: Requirement, id: number) => {
        let passenger: Passenger | undefined;
        r.Stops.forEach(s => {
            s.Passengers.forEach(p => {
                if (p.WorkerId === id) {
                    passenger = p;
                }
            });
        });
        return passenger;
    };

    useEffect(() => {
        const rows = createRowsFromStops(stops, requirements);
        setRows([...rows]);
    }, [requirements, stops, stopsOnLines]);

    const columns: GridColDef[] = [
        {
            field: 'requirementItem',
            headerName: ' ',
            width: 75,
            flex: 0.1,
            disableColumnMenu: true,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            cellClassName: 'requirement-item-cell',
            renderHeader: () => (
                <Tooltip title={showAllLinesOnMap ? 'Összes megálló elrejtése a térképről' : 'Összes megálló megtekintése a térképen'}>
                    <IconButton size='small' sx={{ marginLeft: 0.6 }} onClick={() => setShowAllLinesOnMap(!showAllLinesOnMap)}>
                        {showAllLinesOnMap ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                </Tooltip>
            ),
            renderCell: params => {
                const stop = findStopById(stops, params.row.id);
                if (!stop) {
                    return <></>;
                }
                return <RequirementItem stop={stop} />;
            },
        },
        {
            field: GRID_TREE_DATA_GROUPING_FIELD,
        },
        {
            field: 'name',
            headerName: ' ',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            headerAlign: 'left',
            align: 'left',
            renderCell: params => {
                const stop = findStopById(stops, params.row.id);
                return <RequirementPassengerRow params={params} stop={stop} />;
            },
        },
    ];

    requirements.forEach(r => {
        const dueAt = new Date(Date.parse(r.DueAt));
        columns.push({
            field: 'requirement' + r.DueAt,
            headerName: `${dueAt.toLocaleDateString('hu-HU', { day: '2-digit' })} ${dueAt.toLocaleDateString('hu-HU', { weekday: 'short' })}`,
            width: 40,
            minWidth: 40,
            maxWidth: 40,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'requirement-header',
            renderCell: params => {
                if (params.row.type === 'PASSENGER') {
                    const passenger = getRequirementPassenger(r, params.row.id);
                    if (!passenger) {
                        return <span style={{ margin: '0 2px' }}>-</span>;
                    }
                    return <PassengerCheckboxesCell passenger={passenger} fetchRequirements={fetchRequirements} />;
                }
            },
        });
    });

    const groupingColDef: DataGridProProps['groupingColDef'] = {
        headerName: ' ',
        width: 20,
        minWidth: 20,
        maxWidth: 20,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        resizable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => <CustomGridTreeDataGroupingCell {...params} />,
    };

    return (
        <Stack
            sx={{
                minHeight: '65vh',
                height: '100%',
                width: '100%',
            }}>
            <ErrorBoundary>
                <DataGridPro
                    treeData
                    getTreeDataPath={row => row.path.split('//')}
                    groupingColDef={groupingColDef}
                    rows={rows}
                    columns={columns}
                    rowHeight={30}
                    isGroupExpandedByDefault={params => {
                        return expandedRows[Number(params.id)] || false;
                    }}
                    slots={{
                        footer: DataGridFooter,
                    }}
                    slotProps={{
                        footer: {
                            summary: createSummaryRow(requirements, stopsOnLines),
                        },
                    }}
                    sx={{
                        '& .requirement-header': {
                            width: '5px',
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: 'wrap',
                                lineHeight: 'initial',
                                textAlign: 'center',
                            },
                        },
                        '& .MuiDataGrid-cell': {
                            padding: 0,
                        },
                        '& .requirement-item-cell': {
                            padding: 0,
                        },
                    }}
                />
            </ErrorBoundary>
            <DragOverlay dropAnimation={null}>{activeId ? <Typography sx={{ width: 400 }}>{activeId}</Typography> : null}</DragOverlay>
        </Stack>
    );
};

function RequirementPassengerRow({ params, stop }: { params: GridRenderCellParams; stop: Stop | undefined }) {
    const dragData = {
        id: params.row.id,
        data: {
            stop: stop,
            passengerWorkerId: params.row.id,
            passengerName: params.row.name,
        },
    };

    const { attributes, listeners, setNodeRef } = useDraggable(dragData);

    if (!stop) {
        return (
            <>
                <Box ref={setNodeRef} {...attributes} id={params.row.id} sx={{ display: 'flex', alignItems: 'center' }}>
                    <DragIndicator
                        {...listeners}
                        sx={{
                            verticalAlign: 'middle',
                            cursor: 'grab',
                        }}
                    />
                    <Typography>{params.row.name}</Typography>
                </Box>
            </>
        );
    }

    return (
        <Typography
            m={0}
            p={0}
            paragraph
            sx={{
                display: 'inline-block',
                verticalAlign: 'middle',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                maxWidth: '95%',
                backgroundColor: !stop.AllPassengerHeadquarter ? 'yellow' : '',
            }}
            title={stop.Name}>
            {stop.Name}
        </Typography>
    );
}

export { RequirementList };
